import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, EndpointDisplay, PageSubSection, PatientProfile, ComparisonTable, ReferencesBlock, KMCurve, TextBlock, FeaturedStat, NCCNcallout, ForestPlot } from '../../components';
import MUC from '../../components/StudyDesign/__study-design/muc1';
import { jobCodes } from '../../util/data/jobCodes';
import { StaticImage } from 'gatsby-plugin-image';
import osAcrossPrespecifiedDataTableMob from "../../assets/OS-Forrest-Plot-Composite_Mob.svg";
import osAcrossPrespecifiedDataTableDes from "../../assets/OS-Forrest-Plot-Composite_Desktop.svg";
import styled from 'styled-components';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-A39 - Clinical Trial Results | HCP`,
    keywords: `keynote A39, keytruda and enfortumab vedotin clinical findings`,
    description: `Health care professionals can view clinical trial results for KEYNOTE-A39 in adult patients with locally advanced or metastatic urothelial cancer (mUC).`,
    schemaJsonLD: [
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-cancer-first-line-combination-therapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with enfortumab vedotin, is indicated for the treatment of adult patients with locally advanced (LA) or metastatic urothelial cancer (mUC)."}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-cancer-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals.","":"KEYTRUDA, in combination with enfortumab vedotin, is indicated for the treatment of adult patients with locally advanced (LA) or metastatic urothelial cancer (mUC)"}',
        '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-cancer-first-line-combination-therapy/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠A39","description":"Study Design for KEYNOTE⁠-⁠A39","trialDesign":"An open-label, randomized, multicenter study of KEYTRUDA + EV vs gem/plat in patients with previously untreated LA/mUC (N=886)"}'
    ]
}

//from /src/util/data/indications.js
const indicationId = 36; 
const jobCode = jobCodes[15].jobCode; 

const LimitationContainer = styled.div`
    color: #444444;
    @media (min-width: 320px) and (max-width: 1032px) {
        margin-top: calc(25.51px + 1.4vw) auto;
    }
`;

const PageSectionCustom = styled.div`
> [class^='ComponentWrapper-']{
    margin-bottom:0;
}
[class^='ComponentWrapper-'] [class^='ComponentWrapper-']:last-child{
    margin-bottom:0;
}
    [class^='StudyDesign__MultiFlowWrapper-'] [class^='StudyDesign__MultiFlowItem-']:first-child [class^='Heading__Subtitle-']{
    margin-top:0; 
    @media (max-width:767px){
    margin-top:0;
    }
}
`;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const NCCNcalloutReferences = [
    {
        label: "5.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Bladder Cancer V.6.2024. © National Comprehensive Cancer Network, Inc. 2025. All rights reserved. Accessed January 17, 2025. To view the most recent and complete version of the guidelines, go online to NC‍CN‍.‍org. NCCN = National Comprehensive Cancer Network."
    },
];
const NCCNcalloutData = {
    title: 'Select recommendations from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Bladder Cancer<sup>5</sup>',
    bodyCopy: `<h5>Pembrolizumab (KEYTRUDA) and enfortumab vedotin: THE ONLY PREFERRED CATEGORY 1 SYSTEMIC TREATMENT OPTION recommended for the 1L treatment of patients with locally advanced or metastatic urothelial carcinoma who are cisplatin eligible or cisplatin ineligible</h5>`,
    showBadge: false,
};

const NccnCalloutDefinitions = "<p>Category 1 = based upon high-level evidence, there is uniform NCCN consensus that the intervention is appropriate; 1L = first line; NCCN = National Comprehensive Cancer Network® (NCCN®).</p><p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>";

const pageReferences = [
    {
        label: "1.",
        text: "Powles T, Valderrama BP, Gupta S, et al. Enfortumab vedotin and pembrolizumab in untreated advanced urothelial cancer. <i>N Engl J Med.</i> 2024;390(10):875-888. doi:10.1056/NEJMoa2312117"
    },
    {
        label: "2.",
        text: "Witjes JA, Bruins HM, Carrión A, et al. EAU Guidelines on Muscle-invasive and Metastatic Bladder Cancer. European Association of Urology. Limited update April 2024. Accessed October 25, 2024. https://d56bochluxqnz.cloudfront.net/documents/full-guideline/EAU-Guidelines-on-Muscle-Invasive-and-Metastatic-Bladder-Cancer-2024.pdf",
    },
    {
        label: "3.",
        text: "Powles T, Bellmunt J, Comperat E, et al. ESMO Clinical Practice Guideline interim update on first-line therapy in advanced urothelial carcinoma. ESMO 2024;35:485-490. doi.org/10.1016/j.annonc.2024.03.001",
    },
    {
        label: "4.",
        text: "Powles T, Valderrama BP, Gupta S, et al. Supplementary appendix to: Enfortumab vedotin and pembrolizumab in untreated advanced urothelial cancer. <i>N Engl J Med.</i> 2024;390(10):875-888. doi:10.1056/ NEJMoa2312117",
    },
    {
        label: "5.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Bladder Cancer V.6.2024. © National Comprehensive Cancer Network, Inc. 2025. All rights reserved. Accessed January 17, 2025. To view the most recent and complete version of the guidelines, go online to NC‍CN‍.‍org. NCCN = National Comprehensive Cancer Network.",
    },
]
const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Monotherapy for Advanced Urothelial Carcinoma', url: '/efficacy/urothelial-carcinoma-first-line-monotherapy/' },
                { text: '2L Metastatic Urothelial Carcinoma Monotherapy Post-Platinum Failure', url: '/efficacy/urothelial-carcinoma-second-line-monotherapy/#study-design-for-keynote-045' },

            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/urothelial-cancer-first-line-combination-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
}

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;A39<sup>a</sup>' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;A39' },
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)'},
]

const KMCurveFootnotes = [
    {
        label: "a.",
        text: "KEYNOTE-A39 is also known as EV-302.<sup>1</sup>"
    },
    {
        label: "b.",
        text: "Calculated using stratified Cox proportional hazard regression model."
    },
    {
        label: "c.",
        text: "Two-sided <i>P</i> value based on stratified log-rank test."
    },
]

const KMCurveSecondaryCallout = {
    title: "Nearly 2x the median OS",
    text: `<b>31.5 months</b> median OS with KEYTRUDA + EV vs <b>16.1 months</b> with gem/plat.`,
    alt: ""

}

const KMCurveFootnotesProgressionFree = [
    {
        label: "a.",
        text: "KEYNOTE-A39 is also known as EV-302.<sup>1</sup>"
    },
    {
        label: "b.",
        text: "Calculated using stratified Cox proportional hazard regression model."
    },
    {
        label: "c.",
        text: "Two-sided <i>P</i> value based on stratified log-rank test."
    },
]

const KMCurveSecondaryCalloutProgressionFree = {
    title: "Nearly 2x the median PFS",
    text: `<b>12.5 months</b> median PFS with KEYTRUDA + EV vs <b>6.3 months</b> with gem/plat.`,
    alt: ""
}

const KMCurveAdditionalInformationProgressionFree = [ ]

const DORComparisonFootnotes = [
    {
        label: 'a.',
        text: 'Includes only patients with measurable disease at baseline (n=437 for KEYTRUDA + EV, n=441 for gem/plat) and is based on patients with best overall response as confirmed complete or partial response.'
    },
    {
        label: 'b.',
        text: 'Two-sided <i>P</i> value based on Cochran-Mantel-Haenszel test stratified by PD&#8288;-&#8288;L1 expression, cisplatin eligibility, and liver metastases.'
    },
]
const DORComparisonDefinition = "1L = first line; CI = confidence interval; CPS = combined positive score; HR = hazard ratio; gem/plat = gemcitabine with cisplatin or carboplatin; OS = overall survival; PFS = progression free survival."
const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light" heading={'H3'}>KEYTRUDA + enfortumab vedotin (EV): A 1L Standard of Care for Patients With LA/mUC<sup>1-3</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection title={anchorLinkModelData[0].label}>
                            <KMCurve
                                title="Kaplan-Meier Curve Estimates of Overall Survival in KEYNOTE&#8288;-&#8288;A39"
                                hr="HR<sup>b</sup>=0.47; 95% CI: 0.38, 0.58; <i>P</i><0.0001<sup>c</sup>"
                                limitation='Superior OS with KEYTRUDA + EV vs gemcitabine and cisplatin or carboplatin (gem/plat)'
                                image={() => <StaticImage
                                    placeholder="blurred"
                                    src="../../assets/OS-KM_A39_PI_v6.png"
                                    alt="Kaplan-Meier Estimates of Overall Survival (OS) With KEYTRUDA® (pembrolizumab) + Enfortumab Vedotin in KEYNOTE-A39" />}
                            />

                            <FeaturedStat
                                statistic="53%"
                                title="Reduction in risk of death with KEYTRUDA + EV vs gem/plat"
                                hr="HR<sup>b</sup>=0.47 (95% CI: 0.38, 0.58; <i>P</i><0.0001<sup>c</sup>). Events observed: 133/442 (30%) with KEYTRUDA + EV vs 226/444 (51%) with gem/plat."
                            />

                            <SecondaryCallout 
                                title= "Nearly 2x the median OS"
                                text= "<b>31.5 months</b> median OS with KEYTRUDA + EV vs <b>16.1 months</b> with gem/plat."
                                alt= ""
                                additionalInformation={[
                                    {
                                        text: `
                                            <ul>
                                                <li>Efficacy results were consistent across all stratified patient subgroups.</li>
                                            </ul>
                                        `
                                    },
                                ]}
                                footnotes={KMCurveFootnotes}
                            />
                        </PageSection>

                        <PageSection title={`Exploratory Analysis of Prespecified Subgroups`}>
                              <TextBlock><b>LIMITATIONS:</b> In KEYNOTE-A39<sup>a</sup>, formal statistical testing for OS based on cisplatin eligibility was not conducted. The study was not powered to detect differences in the treatment effect based on cisplatin eligibility. Therefore, results should be interpreted with caution and no conclusions should be drawn.</TextBlock>
                            <KMCurve
                                title="Kaplan-Meier Estimates of OS for Patients Who Were Cisplatin-Eligible<sup>4</sup>"
                                hr="HR<sup>b</sup>=0.53; 95% CI: 0.39, 0.72"
                                limitation=''
                                image={() => <StaticImage
                                    placeholder="blurred"
                                    src="../../assets/WTNY-P00105400_A39_OS_KM_nejmoa2312117_A_r8_7.8pt.png"
                                    alt="Kaplan-Meier Estimates of OS for Patients Who Were Cisplatin-Eligible in KEYNOTE-A39" />}
                                additionalInformation={[
                                    {
                                        text: `
                                            <ul>
                                                <li>HR<sup>b</sup>=0.53; 95% CI: 0.39, 0.72</li>
                                                <li>Events observed: 69/244 with KEYTRUDA + EV vs 106/234 with gem/plat</li>
                                            </ul>  
                                        `
                                    },
                                ]}
                            />
                            <KMCurve
                                title="Kaplan-Meier Estimates of OS for Patients Who Were Cisplatin-Ineligible<sup>4</sup>"
                                hr="HR<sup>b</sup>=0.43; 95% CI: 0.31, 0.59"
                                image={() => <StaticImage
                                    placeholder="blurred"
                                    src="../../assets/WTNY-P00105400_A39_OS_KM_nejmoa2312117_B_r9_7.8pt.png"
                                    alt="Kaplan-Meier Estimates of OS for Patients Who Were Cisplatin-Ineligible in KEYNOTE-A39" />}
                                additionalInformation={[
                                    {
                                        text: `
                                            <ul>
                                                <li>HR<sup>b</sup>=0.43; 95% CI: 0.31, 0.59</li>
                                                <li>Events observed: 64/198 with KEYTRUDA + EV vs 120/210 with gem/plat</li>
                                            </ul>
                                        `
                                    },
                                ]}
                            />
                             <ForestPlot
                                title="OS Across Prespecified Subgroups<sup>1</sup>"
                                limitation='<b>LIMITATIONS:</b> In KEYNOTE-A39, formal statistical testing for these subgroups was not conducted. The study was not powered to detect differences in the treatment effect in these subgroups. Therefore, results should be interpreted with caution and no conclusions should be drawn.'
                                // mobileImage={() => <img src={osAcrossPrespecifiedDataTableMob} style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }} alt="" />}
                                // desktopImage={() => <StaticImage width={740} height={808} quality={90} style={{width:"100%"}}  placeholder="DOMINANT_COLOR" src="../../assets/OS-Forrest-Plot-Composite_Desktop.svg" alt="" />}
                                image={
                                    ()=>
                                        <picture>
                                            <source srcSet={osAcrossPrespecifiedDataTableMob} media='(max-width: 768px)' type="image/webp" />
                                            <img style={{width:"100%"}} src={osAcrossPrespecifiedDataTableDes} alt="OS Across Prespecified Subgroups in KEYNOTE-A39" />
                                        </picture>
                                    
                                }
                                chartAttribution="From: <i>N Engl J Med</i>; Powles T, Valderrama BP, Gupta S, et al. Enfortumab vedotin and pembrolizumab in untreated advanced urothelial cancer. Vol 390(10), Page 884. Copyright &copy; 2024 Massachusetts Medical Society. Reprinted with permission from Massachusetts Medical Society."
                            />
                        </PageSection>

                        <PageSection>
                            <KMCurve
                                title="Kaplan-Meier Estimates for Progression-Free Survival (PFS) in KEYNOTE&#8288;-&#8288;A39<sup>a</sup>"
                                limitation='Superior PFS with KEYTRUDA + EV vs gemcitabine and cisplatin or carboplatin (gem/plat)'
                                hr="HR<sup>b</sup>=0.45; 95% CI: 0.38, 0.54; <i>P</i><0.0001<sup>c</sup>"
                                image={() => <StaticImage
                                    placeholder="blurred"
                                    src="../../assets/PFS-KM_A39_PI_v6.png"
                                    alt="Kaplan-Meier Estimates for Progression-Free Survival (PFS) With KEYTRUDA® (pembrolizumab) + Enfortumab Vedotin in KEYNOTE-A39" />}
                            />

                            <FeaturedStat
                                statistic="55%"
                                title="Reduction in risk of disease progression or death with KEYTRUDA + EV vs gem/plat"
                                hr="HR<sup>b</sup>=0.45 (95% CI: 0.38, 0.54; <i>P</i><0.0001<sup>c</sup>). Events observed: 223/442 (50%) with KEYTRUDA + EV vs 307/444 (69%) with gem/plat."
                            />
                            <SecondaryCallout 
                                title= "Nearly 2x the median PFS"
                                text="<b>12.5 months</b> median PFS with KEYTRUDA + EV vs <b>6.3 months</b> with gem/plat."
                                alt=""
                                additionalInformation={[
                                    {
                                        text: `
                                            <ul>
                                                <li>Efficacy results were consistent across all stratified patient subgroups.</li>
                                            </ul>
                                        `
                                    },
                                ]}
                                footnotes={KMCurveFootnotesProgressionFree}
                            />
                           

                           

                            < EndpointDisplay 
                                title="Superior Objective Response Rate (ORR) With KEYTRUDA + EV vs gem/plat<sup>a,b</sup>"
                                limitation='68% of patients responded to KEYTRUDA + EV vs 44% with gem/plat'
                                middleP="<0.0001"
                                definitions={DORComparisonDefinition} 
                                footnotes={DORComparisonFootnotes} 
                                additionalInformation={[
                                    {
                                        title: '',
                                        text: `
                                            <ul>
                                                <li>Efficacy results were consistent across all stratified patient subgroups.</li>
                                            </ul>
                                        `
                                    },
                                ]}  
                                cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA + EV',
                                            rate: '68%',
                                            ci: '95% CI: 63, 72',
                                            completeResponseRate: '29%',
                                            partialResponseRate: '39%',
                                        },
                                        {
                                            dataType: 'ORR',
                                            label: 'gem/plat',
                                            rate: '44%',
                                            ci: '95% CI: 40, 49',
                                            completeResponseRate: '12%',
                                            partialResponseRate: '32%',
                                        }
                                    ]
                                }
                            />
                        </PageSection>
                        <PageSection bgColor="cloud" title={anchorLinkModelData[1].label}>
                        <PageSectionCustom>
                            <MUC />
                        </PageSectionCustom>
                        </PageSection>

                        <PageSection  title={anchorLinkModelData[2].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                references={NCCNcalloutReferences}
                                definitions={NccnCalloutDefinitions}
                            />
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;

